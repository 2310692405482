<template>
  <div role="group" class="form-group form-row">
    <label for="viewer" class="col-form-label" :class="settings.classes.label">{{ $t(label || 'Email viewer') }}</label>
    <div class="row no-gutters email-container" :class="settings.classes.input">
      <div name="viewer" class="form-control email-viewer h-100" v-html="content" />
    </div>
  </div>
</template>

<script>
const CONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

export default {
  name: 'emailViewer',
  props: {
    content: {
      type: String,
      required: true
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      settings: { ...CONFIG }
    }
  }
}
</script>

<style lang="scss" scoped>
.email-container {
  padding: 5px;
}

.email-viewer {
  /*height: 500px;
  border: 1px solid lightgray;
  padding: 5px;
  background: white;*/
  color: #718096 !important;
}
</style>
