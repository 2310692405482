<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title)" size="lg">
        {{ $t(op_confirm.message) }}
        <template #footer>
          <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
          <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
          <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
        </template>
      </CModal>

      <CModal :show.sync="modalEmail.show" :centered="true" :title="$t('Email control')" size="lg">
        <template v-if="modalEmail.show">
          <ModalEmail :entity="modalEmail.entity" :recipients="modalEmail.recipients" :contact_types="modalEmail.contact_types" :building_options="modalEmail.building_options" :config="{ send_button: 'Send communication' }" :subject_prefix="$t('Communication') + ' - '" @modalUpdated="onModalEmailUpdated" @parentHide="modalEmail.show = false" />
        </template>
        <template #footer-wrapper><span></span></template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit communication') + ` id: ${form.id}` : $t('Create communication') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <!-- // TODO: Large screen side by side ??? -->
            <ACard>
              <AInput label="Subject" v-model="form.subject" :disabled="is_disabled" :isValid="isValid('subject')" :errors="getErrors('subject')" />
            </ACard>

            <ACard v-if="!is_disabled" title="Editor">
              <CRow>
                <div class="col-sm-3" />
                <div class="col-sm-9">
                  <TiptapEditor name="message" class1="editor-content" v-model="form.content" :isValid="isValid('content')" :errors="getErrors('content')" />
                </div>
              </CRow>
            </ACard>

            <ACard title="Preview">
              <EmailViewer label="Preview email" :content="email_content" />
            </ACard>

            <hr />

            <ACard title="Attachments">
              <CRow>
                <div class="col-sm-3" />
                <div class="col-sm-9">
                  <table class="table-striped table-hover w-100">
                    <thead>
                      <tr>
                        <th>{{ $t('File name') }}</th>
                        <th>{{ $t('Size') }}</th>
                        <th width="5" colspan="2" class="text-center">{{ $t('Options') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(file, index) of form.attachments.filter(item => !item.deleted)" :key="`_A${index}`">
                        <td>{{ file.original_name }}</td>
                        <td>{{ (file.size / 1048576).toFixed(2) }} MB</td>
                        <td width="1" class="text-center">
                          <!--a class="btn btn-warning" :href="file.download_url" target="_blank" download><CIcon name="cil-cloud-download"/></a-->
                          <CButton color="warning" @click="onDownloadAttachment(file)"><CIcon name="cil-cloud-download"/></CButton>
                        </td>
                        <td width="1" class="text-center">
                          <CButton color="danger" :disabled="is_disabled" @click="onRemoveAttachment(index, file.id)"><CIcon name="cil-x"/></CButton>
                        </td>
                      </tr>
                      <tr v-for="(file, index) of files" :key="`_F${index}`">
                        <td>{{ file.name }}</td>
                        <td colspan="2">{{ (file.size / 1048576).toFixed(2) }} MB</td>
                        <td width="1" class="text-center">
                          <CButton color="danger" :disabled="is_disabled" @click="onRemoveAttachment(index)"><CIcon name="cil-x"/></CButton>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!is_disabled">
                      <tr>
                        <td colspan="4">
                          <hr />
                          <CInputFile type="file" :value="attachments" :horizontal="{ input: 'col-lg-4 col-sm-6' }" :placeholder="$t('New file')" :custom="true" multiple @change="onAddAttachments" />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </CRow>
            </ACard>

            <template v-if="form.communication_requests.length">
              <hr />

              <!-- // TODO: Use ACRUDTable ? -->
              <ACard title="Send requests">
                <CRow class="mb-1">
                  <!--label class="col-form-label col-sm-3">{{ $t('Send requests') }}</label-->
                  <div class="col-sm-3" />
                  <div class="col-sm-9">
                    <table class="table-striped table-hover w-100">
                      <thead>
                        <tr>
                          <th>{{ $t('Requested') }}</th>
                          <th>{{ $t('Scheduled') }}</th>
                          <!--th>{{ $t('Directed to') }}</th-->
                          <th>{{ $t('Send to') }}</th>
                          <th>{{ $t('Status') }}</th>
                          <th>{{ $t('Options') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(request, index) of form.communication_requests" :key="`_R${index}`">
                          <td>{{ $d(request._created_at) }}</td>
                          <td>{{ $d(request.send_at) }}</td>
                          <!--td>{{ request.directed_to.join(', ') }}</td-->
                          <td>{{ request.send_to }}</td>
                          <td>{{ request.status }}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CRow>
              </ACard>
            </template>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="onSubmit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
            <template v-if="form.id && !is_duplicate">
              <CButton color="warning" class="ml-2" @click="onSend" :disabled="is_send_disabled">{{ $t('Send communication') }}</CButton>
            </template>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import EmailViewer from './elements/emailViewer.vue'
import { DEBUG } from '@/config/config'

export default {
  name: 'CommunicationForm',
  mixins: [formMixin],
  components: { EmailViewer },
  computed: {
    email_content() {
      return this.parseEmail()
    },
    is_send_disabled() {
      return this.isFormDirty || !!this.files.length || !!this.form.attachments.filter(item => item.deleted).length
    },
    is_disabled() {
      return !!this.form.communication_requests.length
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, attachments: [], communication_requests: [] },
      email_body: '',
      modalEmail: {
        show: false,
        button: true,
        prefix: '',
        invoice: false,
        recipients: [],
        building_options: {}
        //onEmail: this.onEmailCommunication
      },

      // Helpers
      files: [],
      lists: {},
      alerts: [],
      validators: {},
      attachments: '',
      is_duplicate: false,

      op_confirm: { show: false, title: 'Remove attachment', message: 'Are you sure you want to remove the attachment?', value: {} }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.is_duplicate = this.$route.query.duplicate
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/communications' + (self.form.id ? `/${self.form.id}/edit` : '/create') + (this.$route.query.duplicate ? '?duplicate=true' : ''), { _lists: 'contact_types,property_units,buildings,apply_options' })
        .then(response => {
          //self.prepareHelpers(response.data._lists)
          self.parseData(response.data)
          self.parseView()
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.files = []
      this.form = data.communication ? data.communication : { id: 0, attachments: [], communication_requests: [] }
      this.lists = data._lists
    },

    parseView() {
      const options = [
        { click: this.onSubmit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      if (this.form.id && !this.is_duplicate) options.push({ click: this.onSend, class: 'ml-2 btn-warning', content: this.$t('Send communication'), disabled: this.is_send_disabled })
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'Communication']: options })
    },

    parseEmail() {
      return (this.form.body || '').replace('XXXX-XXXX', this.form.content || '')
    },

    // SUBMIT

    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      const _lists = self.lists
      if (this.$route.query.duplicate) this.form.duplicate = true
      self.$http[self.form.id ? 'put' : 'post']('admin/communications', self.form, this.files) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/communications/sending_communications/${response.data.communication.id}/edit`).catch(() => {})
          self.showAlert(`Communication request ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
          self.lists = _lists
          this.$v.form.$reset()
          self.parseView()
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the communication.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    },

    // TODO: when using AInputFile only event
    async onAddAttachments(empty, event) {
      let sum = 0
      const self = this
      const max_file_size = this.settings.mail.max_message_size || 7 // TODO: Create function to read config ?

      this.files.forEach(item => (sum += item.size))
      this.form.attachments.forEach(item => (sum += item.size))

      for (const file of event.target.files) {
        const reader = new FileReader()

        // TODO: Validate length and size (5 and 2MB?)
        sum += file.size
        if (self.files.filter(item => item.name === file.name).length + self.form.attachments.filter(item => !item.deleted && item.original_name === file.name).length) {
          self.showAlert(`There is already a file with the name "_XXX_".`, 'warning', file.name)
        } else if (sum > max_file_size * 1048576) {
          self.showAlert(`The total file size is larger than allowed (_XXX1_ MB).`, 'warning', [file.name, max_file_size])
        } else if (file.size > max_file_size * 1048576) {
          self.showAlert(`The file "_XXX0_" is larger than allowed (_XXX1_ MB).`, 'warning', [file.name, max_file_size])
        } else {
          reader.onload = e => {
            if (e && e.target) self.files.push({ name: file.name, size: file.size, type: file.type, _data: e.target.result })
            self.parseView()
          }
          reader.readAsDataURL(file)
        }
      }
      this.attachments = ''
      event.target.value = ''
    },

    onRemoveAttachment(index, id) {
      this.op_confirm.show = true
      this.op_confirm.index = index
      this.op_confirm.id = id
    },

    // TODO:  Create a function
    onDownloadAttachment(file) {
      const link = document.createElement('a')
      link.setAttribute('href', file.download_url)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', file.original_name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    onModalConfirmed(response) {
      if (response) {
        if (this.op_confirm.id) {
          const index = this.form.attachments.findIndex(item => item.id === this.op_confirm.id)
          this.$set(this.form.attachments[index], 'deleted', true)
        } else this.files.splice(this.op_confirm.index, 1)
        this.parseView()
      }
      this.op_confirm.show = false
      this.op_confirm.id = 0
    },

    onSend() {
      this.modalEmail.recipients = [
        { name: 'Send copy to', emails: [{ email: '', checked: false }], type: 'others' },
        { name: 'Admon copy', emails: [{ email: this.property.email, checked: false }], readonly: true, type: 'others', admin: true },
        { name: 'Copy me', emails: [{ email: this.loggedUser.email, checked: false }], readonly: true, type: 'others' }
      ]

      this.modalEmail.prefix = this.$t('Communication')
      this.modalEmail.entity = { id: this.form.id, type: 'communication', origin: 'Communication', subject: this.form.subject, file_name: `${this.modalEmail.prefix} - ${this.form.subject}.xlsx`, file_type: 'xlsx' }
      this.modalEmail.building_options = this.lists
      this.modalEmail.show = true
    },

    onModalEmailUpdated(params) {
      const self = this
      self.modalEmail.show = false
      self.$http
        //.get(`admin/communications/${self.form.id}/send`, params)
        .post(`admin/communications/${self.form.id}/send`, params)
        .then(response => {
          self.showAlert(`Communication sent request created.`)
          if (response.data.communication_request.id) self.form.communication_requests.push(response.data.communication_request)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error sending the communication.`, 'danger')
        })
    }
  }
}
</script>

<style lang="scss">
.editor-content {
  color: #718096 !important;
  background-color: #f0f0f0;
}
</style>
